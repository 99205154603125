import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <Container className="d-flex d-sm-flex align-items-center py-5 h-100">
      <Row>
        <Col md={6} className="d-flex justify-content-center align-self-center justify-content-md-end">
          <img
            className="img-fluid border rounded border-2 border-secondary shadow-lg"
            src="https://avatars.githubusercontent.com/u/31181223?v=4"
            alt="cds"
          />
        </Col>
        <Col md={6} className="text-center m-auto pt-5">
          <h1>Chandrakant Sangale</h1>
          <h1>React Developer</h1>
          <code className="font-monospace text-dark">
            React, HTML, CSS, JavaScript, TypeScript, Material UI, Bootstrap, Jest, Express.js, Node.js, MongoDB,AWS
          </code>
          <p></p>
        </Col>
      </Row>
    </Container>
  );
}

export default App;
